<template>
  <div class="join_us" v-if="show_join_us">
    <div class="close" @click="show_join_us = false">
      <i class="el-icon-error"></i>
    </div>
    <img
      class="cursor"
      @click="goApply"
      src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/lys/mmyy_1.png"
      alt=""
    />
    <span class="text cursor" @click="goApply">{{$t('notwantfreightForward')}}</span>
  </div>
</template>

<script>
import { getAilaDomainName } from "@/baseUtils";
export default {
  name: "goAila",
  data() {
    return {
      show_join_us: true,
    };
  },
  methods: {
    goApply() {
      let res = getAilaDomainName(this.LOCALE);
      console.log(res);
      window.open(`${res}/inquiry/editInquiry/cargoOwner`);
    },
  },
};
</script>

<style scoped lang="less">
.join_us {
  width: 150px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 300px;
  right: 0px;
  z-index: 30;

  .text {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  img {
    width: 150px;
  }

  .close {
    text-align: right;
    width: 100%;

    i {
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>
