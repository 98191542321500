<template>
  <div class="content_area baseInquirySearch">
    <div class="input-wrap clearfix">
      <CountrySelect
          clearable
          class="fl"
          style="width: 250px"
          v-model="queryParams.country"
          :locale="LOCALE"
          :placeTitle="$t('countryPlaceholder')"
      ></CountrySelect>
      <CitySelect
          clearable
          class="fl"
          style="width: 250px; margin: 0 16px"
          v-model="queryParams.city"
          size="small"
          :locale="LOCALE"
          :countryId="queryParams.country"
          :placeTitle="$t('cityPlaceholder')"
      ></CitySelect>
      <CompanySelect
          clearable
          class="fl"
          v-model="queryParams.company"
          style="width: 320px"
          :countryId="queryParams.country"
          :cityId="queryParams.city"
      ></CompanySelect>
      <div class="fr searchBtn">
        <el-button type="primary" size="small" @click="searchSome">
          {{$t('searchbtn')}}
        </el-button>
      </div>
    </div>
    <div class="midSearch clearfix">
      <p class="fl">
        {{ $t("companyScreen") }}
      </p>
      <el-popover @show="showBusiness" placement="right-start" width="600" trigger="click" v-model="bussinessCheck">
        <div>
          <BussinessClassification
              v-if="bussinessCheck"
              @closeClass="bussinessCheck = false"
              @saveClass="searchBussiness"
              class="businessBox"
              :classification="classification"
              :isSelect="business_classification"
              :number="5"
          ></BussinessClassification>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">
          {{ $t("companyGoodBusiness") }}
        </el-button>
      </el-popover>
      <el-popover
          placement="right-start"
          width="600"
          trigger="click"
          v-model="majorShow"
      >
        <div>
          <majorLine
              v-if="majorShow"
              :number="5"
              @cancelLine="majorShow = false"
              @saveLine="saveLine"
              :majorLine="majorLineData"
              :isSelect="havaCheckmajor"
          ></majorLine>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">{{
            $t("BussinessMajor")
          }}
        </el-button>
      </el-popover>
      <el-popover
          placement="right-start"
          width="400"
          trigger="click"
          v-model="yearsCheck"
          @show="yearsShow"
      >
        <yearSelect :isSelect="queryParams.checkYear" @closeYear="yearsCheck = false" @saveYear="saveYear"/>
        <el-button class="screenBtn fl" slot="reference" size="small">
          {{$t('createTimecompany')}}
        </el-button>
      </el-popover>
    </div>
    <div class="haveSelect clearfix" v-if="searchResult.length">
      <p
          class="fl"
          style="max-width: 100px; text-align: right; line-height: 32px"
      >
        {{ $t("companyhaveSeletc") }} ：
      </p>
      <div class="fl" style="width: 1075px">
        <el-tag
            :class="tag.parentKey"
            v-for="(tag, index) in searchResult"
            style="margin-right: 10px; margin-bottom: 10px"
            :key="index"
            disable-transitions
            closable
            @close="closetag(tag, index)"
        >{{ tag.label }}
        </el-tag
        >
      </div>
    </div>
  </div>
</template>


<script>
import CountrySelect from "~/baseComponents/baseInquiry/components/CountrySelect";
import CitySelect from "~/baseComponents/baseInquiry/components/CitySelect";
import CompanySelect from "~/baseComponents/baseInquiry/components/CompanySelect";
import BussinessClassification from "@/baseComponents/BussinessClassification";
import majorLine from "@/baseComponents/majorLine";
import yearSelect from '@/baseComponents/yearSelect';
import {getBusinessObject,setYearsFormat} from "@/basePlugins/filters";
import {shippingLine,setYears} from "@/baseUtils/enumValue";

export default {
  components: {
    CountrySelect,
    CitySelect,
    CompanySelect,
    BussinessClassification,
    majorLine,
    yearSelect
  },
  data() {
    return {
      queryParams: {
        country: null,
        city: null,
        company: null,
        business_classification: "",
        major: "",
        checkYear: "",
      },
      bussinessCheck: false,
      business_classification: {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      },
      classification: [],
      majorShow: false,
      yearsCheck: false,
      searchResult: [],
      havaCheckmajor: [],
      majorLineData: shippingLine,
    }
  },
  mounted() {

  },
  methods: {
    searchSome() {
      this.$emit("search", this.queryParams);
    },
    async showBusiness() {
      this.classification = getBusinessObject([], true);
      console.log(this.classification)
    },
    yearsShow() {
    },
    saveYear(value) {
      this.queryParams.checkYear = value;
      this.yearsCheck = false;
      this.concatSearchResult()
      this.disposeReqFn()
      // this.handleSearch()
    },
    searchBussiness(e) {
      if (e.length > 0) {
        this.business_classification = {
          Convertional: [],
          "Dangerous Cargo": [],
          "Other convertional": [],
          "Particular Cargo": [],
          Services: [],
        };
        if (this.classification.Convertional.length > 0) {
          this.classification.Convertional.forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification.Convertional.push(item);
              }
            });
          });
        } else {
          this.business_classification.Convertional = [];
        }

        if (this.classification["Dangerous Cargo"].length > 0) {
          this.classification["Dangerous Cargo"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Dangerous Cargo"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Dangerous Cargo"] = [];
        }

        if (this.classification["Other convertional"].length > 0) {
          this.classification["Other convertional"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Other convertional"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Other convertional"] = [];
        }

        if (this.classification["Particular Cargo"].length > 0) {
          this.classification["Particular Cargo"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Particular Cargo"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Particular Cargo"] = [];
        }

        if (this.classification.Services.length > 0) {
          this.classification.Services.forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification.Services.push(item);
              }
            });
          });
        } else {
          this.business_classification.Services = [];
        }
      }else{
        this.business_classification={
          Convertional: [],
          "Dangerous Cargo": [],
          "Other convertional": [],
          "Particular Cargo": [],
          Services: [],
        }
      }
      this.concatSearchResult();
      this.bussinessCheck = false;
      this.disposeReqFn();
    },
    // 合并标签
    concatSearchResult() {
      this.searchResult = [];
      let year = [];
      let bussiness = [];
      let havaCheckmajor = [];
      let bussinessConcat = this.business_classification.Convertional.concat(
          this.business_classification["Dangerous Cargo"],
          this.business_classification["Other convertional"],
          this.business_classification["Particular Cargo"],
          this.business_classification.Services
      );
      if (bussinessConcat.length > 0) {
        bussinessConcat.forEach((item) => {
          let obj = {
            value: item.id,
            parentKey: "business_classification",
            label: this.LOCALE === "en" ? item.desc_en : item.desc_zh,
            type: 3,
          };
          bussiness.push(obj);
        });
      }
      if (this.havaCheckmajor.length > 0) {
        this.havaCheckmajor.forEach((item) => {
          let obj = {
            value: item.id,
            parentKey: "lanes",
            label: item.name,
            type: 4,
          };
          havaCheckmajor.push(obj);
        });
      }
      if(this.queryParams.checkYear){
        let obj={
          value:this.queryParams.checkYear,
          parentKey:"found_year",
          label:setYearsFormat(this.queryParams.checkYear,this.LOCALE),
          type: 5,
        }
        year.push(obj)
      }
      this.searchResult = this.searchResult.concat(
          bussiness,
          havaCheckmajor,
          year
      );
    },
    // 处理参数
    disposeReqFn() {
      let bussinessConcat = this.business_classification.Convertional.concat(
          this.business_classification["Dangerous Cargo"],
          this.business_classification["Other convertional"],
          this.business_classification["Particular Cargo"],
          this.business_classification.Services
      );
      if (bussinessConcat.length > 0) {
        let arr = bussinessConcat.map((item) => item.id);
        this.queryParams.business_classification = arr.join();
      } else {
        this.queryParams.business_classification = "";
      }

      if (this.havaCheckmajor.length > 0) {
        let arr = this.havaCheckmajor.map((item) => item.id);
        this.queryParams.major = arr.join();
      } else {
        this.queryParams.major = "";
      }
      this.searchSome()
    },
    closetag(tag, index) {
      this.searchResult.splice(index, 1);
      if (tag.type === 1) {
        const deleteIndex = this.association.findIndex((res) => {
          // 不写return返回的是-1，谜
          return res.value == tag.value;
        });
        if (deleteIndex !== -1) {
          this.association.splice(deleteIndex, 1);
        }
      }
      if (tag.type === 3) {
        let classConIndex = this.business_classification.Convertional.findIndex(
            (res) => {
              return res.id == tag.value;
            }
        );
        if (classConIndex !== -1) {
          this.business_classification.Convertional.splice(classConIndex, 1);
        }

        let classDcIndex = this.business_classification[
            "Dangerous Cargo"
            ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classDcIndex !== -1) {
          this.business_classification["Dangerous Cargo"].splice(
              classDcIndex,
              1
          );
        }

        let classOcoIndex = this.business_classification[
            "Other convertional"
            ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classOcoIndex !== -1) {
          this.business_classification["Other convertional"].splice(
              classOcoIndex,
              1
          );
        }

        let classPcIndex = this.business_classification[
            "Particular Cargo"
            ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classPcIndex !== -1) {
          this.business_classification["Particular Cargo"].splice(
              classPcIndex,
              1
          );
        }

        let classCsIndex = this.business_classification.Services.findIndex(
            (res) => {
              return res.id == tag.value;
            }
        );
        if (classCsIndex !== -1) {
          this.business_classification.Services.splice(classCsIndex, 1);
        }
      }
      if (tag.type === 4) {
        const deleteIndex = this.havaCheckmajor.findIndex((res) => {
          // 不写return返回的是-1，谜
          return res.id == tag.value;
        });
        if (deleteIndex !== -1) {
          this.havaCheckmajor.splice(deleteIndex, 1);
        }
      }
      if(tag.type === 5){
        this.queryParams.checkYear=null
      }
      this.disposeReqFn();
    },
    saveLine(e) {
      if (e.length > 0) {
        this.havaCheckmajor = [];
        this.majorLineData.forEach((item) => {
          e.forEach((id) => {
            if (item.id === id) {
              this.havaCheckmajor.push(item);
            }
          });
        });
      }else{
        this.havaCheckmajor = [];
      }
      this.concatSearchResult();
      this.majorShow = false;
      this.disposeReqFn();
    },
  }
}

</script>

<style lang="less" scoped>
.baseInquirySearch {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 35px 25px;

  .midSearch {
    padding: 20px 0;

    p {
      line-height: 32px;
      font-size: 14px;
      padding-right: 10px;
    }
  }

  .screenBtn {
    margin-right: 15px;
  }
}

.certificate {
  background-color: rgba(240, 250, 255, 0.9);
  border: 1px solid rgba(145, 213, 255, 1);
  color: #91d5ff;

  /deep/ .el-tag__close {
    color: #91d5ff;
  }

  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #1890ff;
  }
}

.business_classification {
  background-color: #f3fffa;
  border: 1px solid rgba(189, 239, 219, 1);
  color: #0dad6d;

  /deep/ .el-tag__close {
    color: #0dad6d;
  }

  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #0dad6d;
  }
}

.wanted_business {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  color: #666666;

  /deep/ .el-tag__close {
    color: #666666;
  }

  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #666666;
  }
}

.interest_city {
  background-color: #fffae9;
  border: 1px solid #fbe5a2;
  color: #f7c52b;

  /deep/ .el-tag__close {
    color: #f7c52b;
  }

  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #f7c52b;
  }
}

.media_flag {
  background-color: #eeffff;
  border: 1px solid #aad8d8;
  color: #5c9d9d;

  /deep/ .el-tag__close {
    color: #5c9d9d;
  }

  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #5c9d9d;
  }
}

.found_year {
  background-color: #fff5ed;
  border: 1px solid #ffd8b8;
  color: #ff943d;

  /deep/ .el-tag__close {
    color: #ff943d;
  }

  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #ff943d;
  }
}
</style>