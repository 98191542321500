<template>
  <div class="wrap">
    <div class="content_area">
      <div class="search-wrap">
        <overseasSearch @search="search" />
      </div>
      <div class="content-wrap" v-loading="loading">
        <overseasItem
          :class="{ isRight: (index + 1) % 3 === 0 }"
          v-for="(item, index) in companyList"
          :key="item.company_id"
          :item="item"
        />
        <noDataImg v-if="!loading && companyList.length === 0"></noDataImg>
      </div>
      <div class="pagination" v-if="total > 12">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="12"
        ></el-pagination>
      </div>
    </div>
    <goAila />
  </div>
</template>

<script>
//   metaInfo() {
//   return {
//    title: this.$t("console"),
//   };
//  },
import overseasItem from "@/baseComponents/baseInquiry/components/overseasItem";
import overseasSearch from "@/baseComponents/baseInquiry/components/overseasSearch";
import goAila from "@/baseComponents/goAila";
import { certificateList } from "@/baseUtils/enumValue";

export default {
  metaInfo() {
    let title=null
    if (this.PJSource === 27) {
      title='厦门货代协会--直通海外'
    }else{
      title=this.$t("Directoverseas ")
    }
    return {
      title
    };
  },
  components: {
    overseasItem,
    overseasSearch,
    goAila,
  },
  data() {
    return {
      loading: false,
      queryForm: {
        country_id: "",
        city_id: "",
        company_name: "",
        business_classification: "",
        lanes: "",
        found_year: "",
      },
      searchVal: {},
      currentPage: 1,
      total: 0,
      companyList: [],
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    search(value) {
      this.searchVal = value;
      this.queryForm.country_id = value.country;
      this.queryForm.city_id = value.city;
      this.queryForm.company_name = value.company;
      this.queryForm.business_classification = value.business_classification;
      this.queryForm.lanes = value.major;
      this.queryForm.found_year = value.checkYear;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = this.queryForm;
      params.start = this.currentPage - 1;
      params.limit = 12;
      params.source = this.PJSource;
      let res = await this.$store.dispatch("baseStore/getOverseasList", params);
      if (res.success) {
        this.loading = false;
        res.data.forEach((item, index) => {
          item.business_classification_list = JSON.parse(
            item.business_classification_list
          );
          item.business_classification_list = this.handleData(
            item.business_classification_list
          );
          item.certificate_list.forEach((ele) => {
            certificateList.forEach((ee) => {
              if (ee.value === ele.certificate_type) {
                ele.logo = ee.logo;
                ele.name = this.LOCALE === "en" ? ee.label_en : ee.label_zh;
              }
            });
          });
        });
        this.companyList = res.data;
        this.total = res.total;
      }
    },
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #f6f7f9;
  padding-bottom: 50px;
  .search-wrap {
    padding: 24px 0;
  }
  .content-wrap {
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;
    position: relative;
    .isRight {
      margin-right: 0;
    }
  }
  .pagination {
    text-align: right;
    padding-bottom: 20px;
  }
}
</style>