<template>
  <div class="item">
    <div class="info">
      <div class="logo-wrap">
        <al-image
          :src="item.logo ? item.logo : DEFAULT_LOGO"
          fit="scale-down"
          class="img"
        ></al-image>
      </div>
      <div class="right-info">
        <div class="companyname textOverflow">
          <a :href="company_adress" target="_blank" rel="nofollow">{{
            item.name_en | priorFormat(item.name_zh, LOCALE)
          }}</a>
        </div>
        <div class="companyAddress textOverflow">
          <span>{{ item.country_id | countryCityFormat(item.city_name_en, item.city_name_zh, LOCALE) }}</span>
          <span class="certificateItem">
            <el-image
              style="margin-left: 16px;width:113px;"
              class="fl"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/member.png"
            ></el-image>
          </span>
          <span
            class="certificateItem"
            v-for="ee in item.certificate_list"
            :key="ee.certificate_type"
          >
            <al-image
              :title="ee.name"
              style="margin-left: 5px"
              class="fl"
              :key="ee.certificate_type"
              v-if="ee.certificate_type !== 0 && ee.logo"
              :src="ee.logo"
            ></al-image>
          </span>
        </div>
      </div>
    </div>
    <div class="business">
      <div class="business-title">{{$t('busenessList')}}：</div>
      <div
        class="business-content"
        v-if="item.business_classification_list.length > 3"
      >
        <a :href="company_adress" target="_blank" rel="nofollow">
          <el-tag
            class="business-tag"
            size="small"
            v-for="ee in item.business_classification_list.slice(0, 3)"
            :key="ee.id"
            >{{ ee.desc_en | priorFormat(ee.desc_zh, LOCALE) }}</el-tag
          >
          <span>...</span>
        </a>
      </div>
      <div class="business-content" v-else>
        <a :href="company_adress" target="_blank" rel="nofollow">
          <el-tag
            class="business-tag"
            size="small"
            v-for="ee in item.business_classification_list"
            :key="ee.id"
            >{{ ee.desc_en | priorFormat(ee.desc_zh, LOCALE) }}</el-tag
          >
        </a>
      </div>
    </div>
    <div class="line">
      <div class="line-title">{{$t('BussinessMajor')}}：</div>
      <div
        class="line-content"
        v-if="item.bcompany_shipping_line_list.length > 4"
      >
        <a :href="company_adress" target="_blank" rel="nofollow">
          <el-tag
            class="line-tag"
            size="small"
            v-for="ee in item.bcompany_shipping_line_list.slice(0, 4)"
            :key="ee.id"
            >{{ ee.name_en | priorFormat(ee.name_cn, LOCALE) }}</el-tag
          >
          <span>...</span>
        </a>
      </div>
      <div  class="line-content" v-else>
        <a :href="company_adress" target="_blank" rel="nofollow">
          <el-tag
            class="line-tag"
            size="small"
            v-for="ee in item.bcompany_shipping_line_list"
            :key="ee.id"
            >{{ ee.name_en | priorFormat(ee.name_cn, LOCALE) }}</el-tag
          >
        </a>
      </div>
    </div>
    <div class="inquiryBtn tr">
      <el-button @click="goToPublishInquiry" type="primary" size="small"
        >{{$t('oneclickinquiry')}}</el-button
      >
    </div>
    <el-dialog
      :visible.sync="showDialog"
      width="350px"
      :close-on-click-modal="false"
    >
      <div class="tc">{{$t('openoverseas')}}</div>
      <div slot="footer" class="dialog-footer" v-if="PJSource != 36">
        <el-button size="small" @click="showDialog = false">{{$t('cancel')}}</el-button>
        <el-button size="small" type="primary" @click="saveContent"
          >{{$t('membership')}}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    company_adress() {
      return `https://cn.ailaworld.com/company/companyDetail/about?company_id=${this.item.company_id}`;
    },
  },
  mounted() {},
  methods: {
    goToPortrait() {
      window.open(
        `https://cn.ailaworld.com/company/companyDetail/about?company_id=${this.item.company_id}`
      );
    },
    goToPublishInquiry() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
      if (!this.USER_INFO.aila_no) {
        this.showDialog = true;
      } else {
        this.$router.push({
          path: "/publishInquiry",
          query: {
            parameter: this._encode({
              company_id: this.item.company_id,
              user_id: this.item.user_id,
              email: this.item.email,
            }),
          },
        });
      }
    },
    saveContent() {
      this.showDialog = false;
      this.$router.push("/memberShip");
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  width: 386px;
  height: 274px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  margin-right: 20px;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 16px;
  .info {
    display: flex;
    .logo-wrap {
      margin-right: 16px;
      .img {
        width: 72px !important;
        height: 72px !important;
      }
    }
    .right-info {
      box-sizing: border-box;
      width: 260px;
      .companyname {
        width: 260px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .companyAddress {
        width: 260px;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        .certificateItem {
          display: flex;
          /deep/ .el-image {
            background-color: #fff;
          }
        }
      }
    }
  }
  .business {
    margin-top: 10px;
    .business-content {
      width: 350px;
      height: 24px;
      margin-top: 5px;
      overflow: hidden;
      cursor: pointer;
      .business-tag {
        margin-right: 5px;
        padding: 0 4px !important;
      }
    }
  }
  .line {
    margin-top: 10px;
    .line-content {
      width: 350px;
      height: 24px;
      margin-top: 5px;
      overflow: hidden;
      cursor: pointer;
      .line-tag {
        margin-right: 5px;
        padding: 0 4px !important;
      }
    }
  }
  .inquiryBtn {
    margin-top: 20px;
  }
}
</style>