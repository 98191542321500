<template>
  <div>
    <el-select
        v-model="country_value"
        filterable
        :placeholder="placeTitle"
        :popper-class="className"
        :size="size"
        :disabled="disabled"
        :clearable="clearable"
    >
      <el-option
          v-for="item in countryList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          :title="item.name"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    placeTitle: {
      type: String,
      default: "Country",
    },
    className: {
      type: String,
      default: "country_List_Select",
    },
    value: {
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hiddenChina:{
      type:Boolean,
      default:false,
    },
    type:{
      type:Number || String,
      default:0,
    }
  },
  mounted() {
    this.initData();
  },
  data() {
    return {
      country_value: null,
      countryList: [],
    };
  },
  methods: {
    async initData() {
      let countryList = await this.$store.dispatch(
          "baseStore/getOverseasCountryList",
          {
            source:this.PJSource,
            kind:3
          }
      );
      let locale = this.locale || this.LOCALE;
      let _countryList = countryList.data.map((item) => {
        return {
          id: item.id,
          name: item[`name_${locale}`],
        };
      });
      this.countryList = _countryList;
      this.$nextTick(() => {
        this.country_value = this.value;
      });
    },
  },
  watch: {
    value(nv) {
      this.country_value = nv;
    },
    country_value(nv) {
      this.$emit("input", nv);
    },
    locale(nv) {
      this.initData();
    },
  },
};
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}
</style>