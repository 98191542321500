<template>
  <div>
    <el-autocomplete
        :placeholder="placeholder"
        v-model="company"
        :fetch-suggestions="querySearch"
        size="small"
        :trigger-on-focus="false"
        style="width: 100%"
        :validate-event="false"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "CompanySelect",
  props: {
    countryId: {
      type: String
    },
    cityId: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      // default: this.$t('Enter_company_name')
    },

  },
  data() {
    return {company: ""};
  },
  computed: {},
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.company = this.value;
    });
  },
  watch: {
    countryId() {
      this.company = "";
    },
    cityId() {
      this.company = "";
    },
    company(nv) {
      this.$emit("input", nv);
    },
  },
  methods: {
    async querySearch(queryString, cb) {
      try {
        let params = {}
        params.kind = 2
        params.source = this.PJSource
        params.company_name = this.company
        if (this.countryId) {
          params.country_id = this.countryId
        }
        if (this.cityId) {
          params.city_id = this.cityId
        }
        let data = await this.$store.dispatch("baseStore/getOverseasCountryList", params);
        if (data.success) {
          data.data.forEach((ee) => {
            ee.value =
              this.LOCALE == "en"? ee.name_en? ee.name_en: ee.name_zh: ee.name_zh? ee.name_zh: ee.name_en;
          });
          cb(data.data);
        }
      } catch (e){
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
</style>
